export default {
  data: () => ({
    alignCssHOptions: [{ text: 'Left', value: 'left' }, { text: 'Center', value: 'center' }, { text: 'Right', value: 'right' }],
    alignHOptions: [{ text: 'Left', value: 'start' }, { text: 'Center', value: 'middle' }, { text: 'Right', value: 'end' }],
    alignHShortOptions: [{ text: 'S', value: 'start' }, { text: 'M', value: 'middle' }, { text: 'E', value: 'end' }],
    alignHOptionsLR: [{ text: 'Left', value: 'start' }, { text: 'Right', value: 'end' }],
    alignVOptions: [{ text: 'Top', value: 'top' }, { text: 'Middle', value: 'middle' }, { text: 'Bottom', value: 'bottom' }],
    alignTBOptions: [{ text: 'Top', value: 'top' }, { text: 'Bottom', value: 'bottom' }],
    axisYPosition: [{ text: 'Left', value: 'left' }, { text: 'Right', value: 'right' }],
    barValuePosOptions: [{ text: 'Above', value: 'above' }, { text: 'Below', value: 'below' }],
    bulletTypeOptions: [{ text: 'Square', value: 'square' }, { text: 'Circle', value: 'circle' }],
    circleLocationOptions: [{ text: 'Inside', value: 'inside' }, { text: 'Outside', value: 'outside' }],
    circleStrokeOptions: [{ text: 'Butt', value: 'butt' }, { text: 'Round', value: 'round' }],
    databaseAccessOptions: [
      { text: "Json", val: "J", desc: 'Json' },
      { text: "Query", val: "Q", desc: 'SQL Query' },
      { text: "SP", val: "S", desc: 'Stored Procedure' }
    ],
    databaseAccessOptionsNJ: [
      { text: "Query", val: "Q", desc: 'SQL Query' },
      { text: "SP", val: "S", desc: 'Stored Procedure' }
    ],
    directionOptions: [{ text: 'Horizontal', value: 'Horizontal' }, { text: 'Vertical', value: 'Vertical' }],
    fontOptions: [
      { text: 'Roboto', value: 'roboto' },
      { text: 'Candara', value: 'candara' },
      { text: 'Century Gothic', value: 'century gothic' },
      { text: 'Georgia', value: 'georgia' },
      { text: 'Tahoma', value: 'tahoma' },
      { text: 'Times new roman', value: 'times new roman' },
      { text: 'Verdana', value: 'verdana' }
    ],
    fontStyleOptions: [
      { text: 'Light', value: 'light' }, 
      { text: 'Light Italic', value: 'light italic' }, 
      { text: 'Regular', value: 'regular' }, 
      { text: 'Regular Italic', value: 'regular italic' }, 
      { text: 'Bold', value: 'bold' }, 
      { text: 'Bold Italic', value: 'bold italic' }
    ],
    fontStyleOptionCSS: [{ text: 'Normal', value: 'normal' }, { text: 'Italic', value: 'italic' }, { text: 'Oblique', value: 'oblique' }, { text: 'Inherit', value: 'inherit' }],
    yesnoOptions: [{ text: 'Yes', value: 'Y' }, { text: 'No', value: 'N' }],
    onoffOptions: [{ text: 'On', value: 'Y' }, { text: 'Off', value: 'N' }],

    componentDirectionOptions: [
      { text: 'N/A', value: ' ' },
      // { text: 'Under Construction', value: 'ServiceUnderConstruction' },
      { text: 'Dashboard', value: 'ServiceDashboard' },
      { text: 'Datatable', value: 'ServiceIntendedDatagrid' },
      { text: 'Page-Svg', value: 'ServicePageSvg' },
      { text: 'Map Board', value: 'ServiceMapBoard' },
      { text: 'Board', value: 'ServiceBoard' },
      { text: 'Photo Gallery', value: 'ServicePhotoGallery' },
      { text: 'Video Gallery', value: 'ServiceVideoGallery' },
      { text: 'File Manager', value: 'ServiceFileManager' },
      { text: '3D Viewer', value: 'Service3DViewer' },
      { text: 'Custom Page', value: 'CUSTOM' },
      { text: 'Component Page', value: 'ServiceCompPage' }
    ],
    targetOptions: [
      { text: 'Slide Modal', value: 'modal' },
      { text: 'Pop-Up', value: 'popup' },
      { text: 'URL', value: 'url' },
    ],
    targetCompOptions: [
      { text: 'Dashboard'     , value: 'ServiceDashboard' },
      { text: 'Datatable'     , value: 'ServiceDatatable' },
      { text: 'Page-SVG'      , value: 'ServicePageSvg'   },
      { text: 'Svg-Tab'       , value: 'ServiceSvgTabs'   },
      { text: 'Map Board'     , value: 'ServiceMapBoard'  },
      { text: 'Component Page', value: 'ServiceCompPage'  },
    ],
    targetCompPageOptions: [
      { id: 1, code: 'ServicePhotoGallary', name: 'Service Photo-Gallary' },
      { id: 2, code: 'Service3DViews', name: 'Service 3D-Views' },
      { id: 3, code: 'ServiceSubsystemComment', name: 'Service Subsystem Comment' },
    ],
    jobStatusOption: [
      { text: 'Not Started', value: 'not', class: 'gray' },
      { text: 'Hold', value: 'hold', class: 'pink' },
      { text: 'Progress', value: 'prog', class: 'yellow' },
      { text: 'Complete', value: 'complete', class: 'green' },
    ],
    workTypeOption: [
      { text: 'New Service Item', value: 'New' },
      { text: 'Changed Data', value: 'Data' },
      { text: 'Changed Report', value: 'Report' },
      { text: 'Func. Improvement', value: 'Function' },
      { text: 'Modification', value: 'Modify' },
    ],
    workStatusOption: [
      { text: 'Planning', value: 'plan' },
      { text: 'Hold', value: 'hold' },
      { text: 'Issued', value: 'issued' },
      { text: 'Progress', value: 'prog' },
      { text: 'Closed', value: 'closed' },
    ],
    reportCatOptions: {
      Overview: [
        // { text: 'All', value: 'ALL' }
      ],
      Execution: [
        // { text: 'All', value: 'ALL' }
      ],
      Weekly: [
        // { text: 'All', value: 'ALL' }
        // { text: 'Site Weekly Report', value: '1WSW' },
      ],
      Organization: [
        // { text: 'All', value: 'ALL' }
        // { text: 'Site Weekly Report', value: '1WSW' },
      ],
      Dashboard: [
        // { text: 'All', value: 'ALL' }
        // { text: 'Site Weekly Report', value: '1WSW' },
      ],
      NewsLetter: [
        // { text: 'All', value: 'ALL' }
        // { text: 'Site Weekly Report', value: '1WSW' },
      ],
      Fabrication: [
        { text: 'Monthly Report', value: '1FMR' },
        { text: 'Weekly Report', value: '2FWR' },
        { text: 'Daily Report', value: '3FDR' },
      ],
      Jgs: [
        { text: 'Piping Status by Module', value: '1JPM' },
        { text: 'Weekly Report', value: '2JWR' },
      ],
      special: [
        { text: 'Fabrication Method', value: '1SFM' },
        { text: 'Fabrication Yard Layout', value: '2SYL' },
        { text: 'Equipment Layout', value: '3SEL' },
        { text: 'Equipment Layout by room', value: '4SER' },
      ],
    },
    langOptions: [
      { text: 'English', value: 'en' },
      { text: '中文', value: 'cn' },
    ],
    officeName:[
      'ExxonMobil',
      'SEI',
      'JGS',
      'Guest',
    ]
  }),
  methods: {
    getReportCatName(catcode, groupcode) {
      if(!catcode) return []
      
      let option = this.reportCatOptions[catcode].find(opt => opt.value == groupcode)
      return option ? option.text : ''
    },
    getJobStatusName(code) {
      let option = this.jobStatusOption.find(opt => opt.value == code)
      return option ? option.text : ''
    },
    getWorkTypeName(code) {
      let option = this.workTypeOption.find(opt => opt.value == code)
      return option ? option.text : ''
    },
    getWorkStatusName(code) {
      let option = this.workStatusOption.find(opt => opt.value == code)
      return option ? option.text : ''
    }
  }
}
